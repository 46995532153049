import { Prop, Component, Vue, Ref } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import { Input } from 'ant-design-vue';

@Component
export default class TokenExInput extends Vue {
    @Prop() public props!: LooseObject;
    private value!: LooseObject;
    private validationArray: ValidationModel[] = [];

    protected created() {
        EventBus.$on('requestHiddenValues', this.submitValue);
        this.createValidationObject();
    }

    protected beforeDestroy() {
        EventBus.$off('requestHiddenValues', this.submitValue);
    }

    private isValueValid() {
        const inputValue = (document.getElementById(this.props.elementId) as HTMLInputElement).value;
        if (inputValue !== undefined && inputValue !== '') {
            this.value = JSON.parse(inputValue);
            if (this.value.token !== undefined) {
                this.$emit('update:value', inputValue);
                return true;
            }
        }
        return false;
    }

    private createValidationObject() {
        this.validationArray = [
            {
                required: true,
                message: 'Card details are invalid',
                trigger: 'blur',
            },
        ];
    }

    private submitValue() {
        if (this.isValueValid()) {
            EventBus.$emit('submitHiddenValue', this.props.elementId, this.value, this.props.label);
        }
    }
}
