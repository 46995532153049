import { Prop, Component, Vue, Ref, Watch } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import LooseObject from '@/models/Objects/LooseObject';

@Component
export default class TextInput extends Vue {
    @Prop() public props!: LooseObject;
    @Prop() public value!: string;
    private validationArray: ValidationModel[] = [];
    private inputValue = '';


    protected created() {
        EventBus.$on('requestValue', this.submitValue);
        EventBus.$on('Updating ' + this.props.elementId, (data: string) => { this.updateInputValues(data); });
        this.createValidationObject();
        this.checkForEditValue();
    }

    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
        EventBus.$off('Updating ' + this.props.elementId, (data: string) => { this.updateInputValues(data); });
    }

    private async onChange() {
        this.$emit('update:value', this.props.options);
    }
    private isValueValid() {
        if (this.props.required && !this.value) {
            return false;
        }
        let valid = true;
        if (this.props.minLength && this.props.maxLength) {
            const { min, max } = { min: this.props.minLength, max: this.props.maxLength };
            valid = this.value.length >= min && this.value.length <= max;
        }
        if (this.props.pattern && valid) {
            const match = this.value.match(new RegExp(this.props.pattern));
            valid = match ? true : false;
        }
        return valid;
    }

    private submitForm() {
        document.getElementById('submit')?.click();
    }

    private submitValue() {
        if (this.isValueValid()) {
            EventBus.$emit('submitValue', this.props.elementId, this.value, this.props.label);
        }
    }

    private createValidationObject() {
        this.validationArray = [
            {
                required: this.props.required,
                message: this.props.requiredMessage || 'Please fill out this field',
                trigger: 'blur',
            },
            {
                min: this.props.minLength,
                max: this.props.maxLength,
                message: this.props.validationMessage || `please keep between ${this.props.minLength} and ${this.props.maxLength} characters`,
                trigger: 'blur',
            },
        ];
        if (this.props.pattern !== undefined && this.props.pattern !== '') {
            this.validationArray.push(
                {
                    pattern: new RegExp(this.props.pattern),
                    message: this.props.validationMessage || `value does not meet the required input pattern`,
                    trigger: 'blur',
                },
            );
        }
    }

    private updateInputValues(inputValue: string) {
        this.inputValue = inputValue;
        this.$emit('update:value', this.inputValue);
    }

    private checkForEditValue() {
        if (this.props.defaultEditValue !== null) {
            this.updateInputValues(this.props.defaultEditValue.toString());
        } else {
            this.updateInputValues('');
        }
    }
}
