import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import moment from 'moment';
import store from '@/store';
import { getFormattedString } from '@/utilities/mutate';

const requiredFormat: string = 'YYYY-MM-DD';

@Component
export default class DateInput extends Vue {
  @Prop() public props!: LooseObject;

  private validationArray: ValidationModel[] = [];

  private displayformat = this.props.format || 'DD-MM-YYYY';

  protected created() {
    EventBus.$on('requestValue', this.submitValue);
    this.createValidationObject();
    if (this.props.defaultEditValue !== null) {
      this.$emit('update:value', this.props.defaultEditValue);
      this.props.value = this.props.defaultEditValue;
    }
  }

  protected beforeDestroy() {
    EventBus.$off('requestValue', this.submitValue);
  }

  private setInitialObjectValues() {
    this.$emit('update:value', this.props.value);
  }

  private input() {
    this.$emit('update:value', this.props.value);
  }

  private isValueValid() {
    const val = moment(this.props.value);
    const min = moment(this.props.minDate, requiredFormat);
    const max = moment(this.props.maxDate, requiredFormat);
    if (this.props.required && !val) {
      return false;
    }
    if (!val.isBetween(min, max, 'day', '[]')) {
      return false;
    }
    return true;
  }

  private submitForm() {
      document.getElementById('submit')?.click();
  }

  private submitValue() {
    if (this.isValueValid()) {
      EventBus.$emit(
        'submitValue',
        this.props.elementId,
        this.props.value,
        this.props.label,
        getFormattedString(this.props, this.props.value),
      );
    }
  }

  private createValidationObject() {
    this.validationArray = [
      {
        required: this.props.required,
        message: this.props.requiredMessage || 'Please fill out this field',
        trigger: 'change',
      },
      { validator: this.checkMinMaxDates, trigger: 'change' },
    ];
  }

  private checkMinMaxDates(rule: any, value: string, callback: any) {
    const val = moment(value);
    const min = moment(this.props.minDate, requiredFormat);
    const max = moment(this.props.maxDate, requiredFormat);

    if (val.isBefore(min)) {
      callback(new Error(this.props.validationMessage || 'Please enter a valid date'));
    }
    if (val.isAfter(max)) {
      callback(new Error(this.props.validationMessage || 'Date entered does not meet the valid criteria'));
    } else {
      callback();
    }
  }
}
